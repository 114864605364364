<template>
  <div class="layout" id="auto-dialer-layout">
    <div class="p-4 rounded-lg card content">
      <div class="d-flex justify-content-center align-items-center py-2 header">
        <slot name="header"></slot>
      </div>
      <Stepper
        :steps="[
          $t('auto-dialer.create.steps.0'),
          $t('auto-dialer.create.steps.1')
        ]"
        :value="step"
        noNavigation
      >
        <template v-slot:step-0>
          <slot name="step-0"></slot>
        </template>

        <template v-slot:step-1>
          <slot name="step-1"></slot>
        </template>
      </Stepper>
    </div>
  </div>
</template>

<script>
import Stepper from '../../../elements/Stepper.vue'

export default {
  components: {
    Stepper
  },
  props: {
    step: {
      type: Number,
      default: 0
    }
  }
}
</script>
