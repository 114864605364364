<template>
  <div class="mt-5 stepper-container">
    <div class="position-relative d-flex align-items-center mb-4 stepper">
      <div class="step-line active"></div>
      <fragment v-for="(step, index) in steps" :key="index">
        <div
          class="step"
          :class="{
            active: value === index,
            completed: value > index
          }"
          @click="!noNavigation && goToStep(index)"
        >
          <div class="step-number">
            <span class="text-center" v-if="value <= index">
              {{ index + 1 }}
            </span>
            <i class="fa fa-check" v-else></i>
          </div>
          <div class="step-label">{{ step }}</div>
        </div>
        <div class="step-line" :class="{ active: value > index }"></div>
      </fragment>
    </div>

    <div class="mb-4 step-content">
      <slot :name="'step-' + value"></slot>
    </div>

    <div
      class="d-flex justify-content-end stepper-pagination"
      v-if="!noNavigation"
    >
      <button
        class="px-3 py-2 btn btn-secondary"
        v-if="value !== 0"
        @click="prevStep"
      >
        Previous
      </button>

      <button
        v-if="value < steps.length - 1"
        class="px-3 py-2 btn btn-primary"
        @click="nextStep"
      >
        Next
      </button>

      <button v-else class="px-3 py-2 btn btn-success" @click="submit">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      default: 0
    },
    noNavigation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextStep() {
      if (this.value < this.steps.length - 1) {
        this.$emit('input', this.value + 1)
      }
    },
    prevStep() {
      if (this.value > 0) {
        this.$emit('input', this.value - 1)
      }
    },
    goToStep(index) {
      if (index < this.steps.length) {
        this.$emit('input', index)
      }
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="scss">
.stepper {
  display: flex;
  position: relative;
  width: 100%;
}

.step-container {
  display: flex;
  align-items: center;
}

.step {
  z-index: 1;
  cursor: pointer;
}

.stepper-pagination {
  gap: 10px;
}

.step-number {
  background-color: #b9c2c9;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
}

.step-label {
  margin-top: 8px;
}

.step.active .step-number {
  background-color: #008ecf;
}

.step.completed .step-number {
  background-color: #008ecf;
}

.step-line {
  width: 100%;
  flex: 1;
  height: 4px;
  background-color: #b9c2c9;
  margin-top: -30px;

  &.active {
    background-color: #008ecf;
  }
}
</style>
