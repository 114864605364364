<template>
  <div class="page" id="create-auto-dialer">
    <Layout :step="0">
      <template v-slot:header>
        <h2 class="m-0">
          {{
            $t('auto-dialer.create.step1.title', {
              campaignName: model.name
            })
          }}
        </h2>
      </template>
      <template v-slot:step-0>
        <form class="form-container">
          <div class="row">
            <div class="col-12">
              <h4 class="mt-0 mb-2">
                {{ $t('auto-dialer.create.step1.form.basic') }}
              </h4>
            </div>
            <div class="mb-2 col-12">
              <Field
                :label="$t('auto-dialer.create.step1.form.name.label')"
                :placeholder="
                  $t('auto-dialer.create.step1.form.name.placeholder')
                "
                :info="$t('auto-dialer.create.step1.form.name.info')"
                v-model="model.name"
                :error="errors[`name`]"
              />
            </div>
            <div class="mb-2 col-12 col-lg-4 col-md-6">
              <Field
                :label="
                  $t(
                    'auto-dialer.create.step1.form.waiting-customer-count.label'
                  )
                "
                :info="
                  $t(
                    'auto-dialer.create.step1.form.waiting-customer-count.info'
                  )
                "
                :error="errors[`waitingCustomerCount`]"
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.waitingCustomerCount"
                  min="0"
                  max="10"
                  wrap
                  placeholder="-"
                />
              </Field>
            </div>

            <div class="mb-2 col-12 col-lg-4 col-md-6">
              <Field
                :label="$t('auto-dialer.create.step1.form.trials-count.label')"
                :error="errors[`trialsCount`]"
                :info="$t('auto-dialer.create.step1.form.trials-count.info')"
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.trialsCount"
                  min="1"
                  max="10"
                  wrap
                  placeholder="-"
                />
              </Field>
            </div>

            <div class="mb-2 col-12 col-lg-4 col-md-6">
              <Field
                :label="$t('auto-dialer.create.step1.form.wrap-up-time.label')"
                :error="errors[`wrapUpTime`]"
                :info="$t('auto-dialer.create.step1.form.wrap-up-time.info')"
              >
                <b-form-input
                  class="border"
                  v-model="model.wrapUpTime"
                  type="number"
                  min="10"
                  @keydown="onlyAcceptNumbers"
                />
              </Field>
            </div>
            <div class="mb-2 col-12 col-lg-4 col-md-6">
              <Field
                :label="
                  $t(
                    'auto-dialer.create.step1.form.delay-minutes-between-trials.label'
                  )
                "
                :error="errors[`delayMinutesBetweenTrials`]"
                :info="
                  $t(
                    'auto-dialer.create.step1.form.delay-minutes-between-trials.info'
                  )
                "
              >
                <b-form-input
                  class="border"
                  v-model="model.delayMinutesBetweenTrials"
                  type="number"
                  min="5"
                  @keydown="onlyAcceptNumbers"
                />
              </Field>
            </div>

            <div class="col-12 row">
              <div class="mb-2 col-4">
                <b-form-checkbox v-model="model.hideCallerInfo">
                  {{ $t('auto-dialer.create.step1.form.hideCallerInfo.label') }}
                </b-form-checkbox>
              </div>
              <div class="mb-2 col-4">
                <b-form-checkbox v-model="model.agentCanLogoutAndRejoin">
                  {{
                    $t(
                      'auto-dialer.create.step1.form.agentCanLogoutAndRejoin.label'
                    )
                  }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <hr class="w-100" />
          <div class="row">
            <div class="col-12">
              <h4 class="mt-0 mb-2">
                {{ $t('auto-dialer.create.step1.form.time') }}
              </h4>
            </div>
            <div class="mb-2 col-12 col-lg-6">
              <Field
                :label="$t('auto-dialer.create.step1.form.duration-type.label')"
                :error="errors[`durationType`]"
                :info="$t('auto-dialer.create.step1.form.duration-type.info')"
              >
                <b-form-select
                  class="border"
                  :options="durationTypes"
                  v-model="model.durationType"
                  :allowEmpty="false"
                />
              </Field>
            </div>
            <div class="col-12 col-lg-6">
              <Field
                :label="$t('auto-dialer.create.step1.form.max-wait-time.label')"
                :error="errors[`maxWaitTime`]"
                :info="$t('auto-dialer.create.step1.form.max-wait-time.info')"
              >
                <b-form-input
                  class="border"
                  v-model="model.maxWaitTime"
                  type="number"
                  min="50"
                  @keydown="onlyAcceptNumbers"
                />
              </Field>
            </div>
            <div
              class="mb-2 col-12 col-lg-4 col-md-6"
              v-if="model.durationType === 'time-limited'"
            >
              <Field
                :label="$t('auto-dialer.create.step1.form.from-time.label')"
                :error="errors[`fromTime`]"
                :info="$t('auto-dialer.create.step1.form.from-time.info')"
              >
                <b-form-input
                  class="border"
                  v-model="model.fromTime"
                  type="time"
                />
              </Field>
            </div>
            <div
              class="mb-2 col-12 col-lg-4 col-md-6"
              v-if="model.durationType === 'time-limited'"
            >
              <Field
                :label="$t('auto-dialer.create.step1.form.to-time.label')"
                :error="errors[`toTime`]"
                :info="$t('auto-dialer.create.step1.form.to-time.info')"
              >
                <b-form-input
                  class="border"
                  v-model="model.toTime"
                  type="time"
                />
              </Field>
            </div>
            <div
              class="mb-2 col-12 col-lg-4 col-md-6"
              v-if="model.durationType === 'time-limited'"
            >
              <Field
                :label="$t('auto-dialer.create.step1.form.time-zone.label')"
                :error="errors[`timezone`]"
                :info="$t('auto-dialer.create.step1.form.time-zone.info')"
              >
                <b-form-select
                  class="border"
                  :options="timezones"
                  v-model="model.timezone"
                  :allowEmpty="false"
                />
              </Field>
            </div>
          </div>
          <hr class="w-100" />
          <div class="row">
            <div class="col-12">
              <h4 class="mt-0 mb-2">
                {{ $t('auto-dialer.create.step1.form.sound') }}
              </h4>
            </div>

            <div class="mb-2 col-12">
              <b-form-checkbox v-model="model.hasAnnouncement">
                {{ $t('auto-dialer.create.step1.form.hasAnnouncement.label') }}
              </b-form-checkbox>
            </div>

            <div class="mb-2 col-12 col-md-6">
              <Field
                :label="$t('auto-dialer.create.step1.form.music-on-hold.label')"
                :info="$t('auto-dialer.create.step1.form.music-on-hold.info')"
                :error="errors[`loopSoundFile`]"
              >
                <Dropzone
                  class="mb-2"
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.loopSoundFile"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{
                        $t('auto-dialer.create.step1.form.music-on-hold.title')
                      }}
                    </h3>
                    <div class="subtitle">
                      {{
                        $t(
                          'auto-dialer.create.step1.form.music-on-hold.subtitle'
                        )
                      }}
                    </div>
                    <div class="description">
                      {{
                        $t(
                          'auto-dialer.create.step1.form.music-on-hold.description'
                        )
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>

            <div class="mb-2 col-12 col-md-6" v-if="model.hasAnnouncement">
              <Field
                :label="$t('auto-dialer.create.step1.form.announcement.label')"
                :error="errors[`mainSoundFile`]"
                :info="$t('auto-dialer.create.step1.form.announcement.info')"
              >
                <Dropzone
                  class="mb-2"
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.mainSoundFile"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{
                        $t('auto-dialer.create.step1.form.announcement.title')
                      }}
                    </h3>
                    <div class="subtitle">
                      {{
                        $t(
                          'auto-dialer.create.step1.form.announcement.subtitle'
                        )
                      }}
                    </div>
                    <div class="description">
                      {{
                        $t(
                          'auto-dialer.create.step1.form.announcement.description'
                        )
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
          </div>
          <hr class="w-100" />
          <!-- <div class="row">
            <div class="col-12">
              <b-form-checkbox v-model="model.recording.enabled">
                <h4 class="mt-0 mb-2">
                  {{ $t('auto-dialer.create.step1.form.recording.title') }}
                </h4>
              </b-form-checkbox>
            </div>

            <div
              class="align-items-center col-12 row"
              v-if="model.recording.enabled"
            >
              <div class="col-12 col-lg-6">
                <Field
                  :label="
                    $t('auto-dialer.create.step1.form.recording.format.label')
                  "
                  :error="errors[`recording.format`]"
                >
                  <b-form-select
                    class="border"
                    v-model="model.recording.format"
                    :options="[
                      { text: 'MP3', value: 'mp3' },
                      { text: 'GSM', value: 'gsm' },
                      { text: 'MP3', value: 'mp3' },
                      { text: 'WAV49', value: 'wav49' }
                    ]"
                  />
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-checkbox v-model="model.recording.beep">
                  {{ $t('auto-dialer.create.step1.form.recording.beep.label') }}
                </b-form-checkbox>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="
                    $t(
                      'auto-dialer.create.step1.form.recording.max-duration.label'
                    )
                  "
                  :error="errors[`recording.maxDuration`]"
                >
                  <b-form-input
                    class="border"
                    v-model="model.recording.maxDuration"
                    type="number"
                    min="0"
                    max="10000"
                    @keydown="onlyAcceptNumbers"
                  />
                </Field>
              </div>
            </div>
          </div>
          <hr class="w-100" /> -->
          <div class="callers-container row">
            <div class="col-12">
              <h4 class="mt-0 mb-2">
                {{ $t('auto-dialer.create.step1.form.caller-ids.label') }}
              </h4>
            </div>

            <div
              v-for="(caller, idx) in model.callers"
              :key="caller.id"
              class="align-items-start col-12 row"
            >
              <div class="col-11 row">
                <div class="col-12 col-md-6">
                  <Field
                    :label="
                      $t('auto-dialer.create.step1.form.destinations.label')
                    "
                    :info="
                      $t('auto-dialer.create.step1.form.destinations.info')
                    "
                    :error="errors[`callers[${idx}].destination`]"
                  >
                    <b-form-select
                      class="border"
                      @change="onCountryChange($event, idx, caller.id)"
                      v-model="model.callers[idx].destination"
                    >
                      <b-form-select-option
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.code"
                        :disabled="
                          model.callers[idx].destination !== country.code &&
                            selectedCountries.includes(country.code)
                        "
                      >
                        {{ country.emoji }} {{ country.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </Field>
                </div>

                <div class="col-12 col-md-6">
                  <Field
                    :label="
                      $t('auto-dialer.create.step1.form.caller-ids.label')
                    "
                    :info="$t('auto-dialer.create.step1.form.caller-ids.info')"
                    :error="errors[`callers[${idx}].callerNumber`]"
                  >
                    <b-form-select
                      class="border"
                      v-model="model.callers[idx].callerNumber"
                      :options="callerNumbers"
                    />
                  </Field>
                </div>
              </div>

              <div class="mt-4 col-1">
                <b-button
                  class="px-3 py-2"
                  variant="danger"
                  @click="handleRemoveCaller(idx)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
              </div>
            </div>

            <p class="mx-3 text-danger">
              {{ errors[`callers`] }}
            </p>

            <div class="col-12">
              <b-button class="px-3 py-2" @click="handleAddCaller">
                {{ $t('auto-dialer.create.step1.form.add-caller') }}
              </b-button>
            </div>
          </div>

          <hr class="w-100" />
          <div class="agents row">
            <div class="col-12">
              <h4 class="mt-0 mb-2">
                {{ $t('auto-dialer.create.step1.form.agents.title') }}
              </h4>
            </div>
            <div class="col-12">
              <Field
                :label="$t('auto-dialer.create.step1.form.agents.agent.label')"
                :info="$t('auto-dialer.create.step1.form.agents.agent.info')"
                :error="errors[`agents`]"
              >
                <Select
                  searchable
                  multiple
                  :placeholder="
                    $t('auto-dialer.create.step1.form.agents.agent.placeholder')
                  "
                  v-model="model.agents"
                  :options="extensions"
                  :preselectFirst="false"
                />
              </Field>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-4 stepper-pagination">
            <button
              class="px-3 py-2 btn btn-primary"
              @click="submit"
              type="button"
              :disabled="loading"
            >
              {{
                loading
                  ? $t('auto-dialer.create.step1.form.loading')
                  : $t('auto-dialer.create.step1.form.continue')
              }}
            </button>
          </div>
        </form>
      </template>
    </Layout>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import { onlyAcceptNumbers, removeNullEntries } from '../../../utils/utils'
import timezones from '../../../constants/timezones.json'
import Dropzone from '../../../elements/Dropzone.vue'
import Layout from './layout.vue'

import momentTimezone from 'moment-timezone'
import vocabService from '../../../services/vocab.service'
import AutoDialerService from '../../../services/autoDialer.service'
import { array, boolean, mixed, number, object, string } from 'yup'
import { yupToKV } from '../../../utils/yup'
import ExtensionService from '../../../services/extension.service'

export default {
  components: {
    Field,
    Layout,
    Dropzone
  },
  computed: {
    timezones() {
      return timezones
    },
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('auto-dialer.create.step1.form.name.required')),
        waitingCustomerCount: number()
          .min(
            0,
            this.$t('auto-dialer.create.step1.form.waiting-customer-count.min')
          )
          .required(
            this.$t(
              'auto-dialer.create.step1.form.waiting-customer-count.required'
            )
          ),
        trialsCount: number()
          .min(1, this.$t('auto-dialer.create.step1.form.trials-count.min'))
          .max(10, this.$t('auto-dialer.create.step1.form.trials-count.max'))
          .required(
            this.$t('auto-dialer.create.step1.form.trials-count.required')
          ),
        wrapUpTime: number()
          .min(
            10,
            this.$t('auto-dialer.create.step1.form.wrap-up-time.min', {
              min: 10
            })
          )
          .required(
            this.$t('auto-dialer.create.step1.form.wrap-up-time.required')
          ),
        delayMinutesBetweenTrials: number()
          .min(
            5,
            this.$t(
              'auto-dialer.create.step1.form.delay-minutes-between-trials.min',
              { min: 5 }
            )
          )
          .required(
            this.$t(
              'auto-dialer.create.step1.form.delay-minutes-between-trials.required'
            )
          ),
        durationType: string()
          .trim()
          .oneOf(
            ['time-limited', 'agent-availability'],
            this.$t('auto-dialer.create.step1.form.duration-type.invalid')
          )
          .required(
            this.$t('auto-dialer.create.step1.form.duration-type.required')
          ),
        maxWaitTime: number().min(
          50,
          this.$t('auto-dialer.create.step1.form.max-wait-time.min', {
            min: 50
          })
        ),
        fromTime: string()
          .trim()
          .when('durationType', {
            is: 'time-limited',
            then: () =>
              string().required(
                this.$t('auto-dialer.create.step1.form.from-time.required')
              )
          }),
        toTime: string()
          .trim()
          .when('durationType', {
            is: 'time-limited',
            then: () =>
              string().required(
                this.$t('auto-dialer.create.step1.form.to-time.required')
              )
          }),
        timezone: string()
          .trim()
          .when('durationType', {
            is: 'time-limited',
            then: () =>
              string().required(
                this.$t('auto-dialer.create.step1.form.timezone.required')
              )
          }),
        mainSoundFile: mixed().when('hasAnnouncement', {
          is: true,
          then: () =>
            mixed().required(
              this.$t('auto-dialer.create.step1.form.announcement.required')
            )
        }),
        loopSoundFile: mixed().required(
          this.$t('auto-dialer.create.step1.form.music-on-hold.required')
        ),
        recording: object().shape({
          enabled: boolean().required(
            this.$t('auto-dialer.create.step1.form.recording.enabled.required')
          ),
          format: string()
            .trim()
            .when('enabled', {
              is: true,
              then: () =>
                string().required(
                  this.$t(
                    'auto-dialer.create.step1.form.recording.format.required'
                  )
                )
            }),
          beep: boolean().when('enabled', {
            is: true,
            then: () =>
              boolean().required(
                this.$t('auto-dialer.create.step1.form.recording.beep.required')
              )
          }),
          maxDuration: number().when('enabled', {
            is: true,
            then: () =>
              number()
                .min(
                  0,
                  this.$t(
                    'auto-dialer.create.step1.form.recording.max-duration.min',
                    { min: 0 }
                  )
                )
                .max(
                  86400,
                  this.$t(
                    'auto-dialer.create.step1.form.recording.max-duration.max',
                    { max: 86400 }
                  )
                )
                .required(
                  this.$t(
                    'auto-dialer.create.step1.form.recording.max-duration.required'
                  )
                )
          })
        }),
        callers: array()
          .of(
            object().shape({
              destination: string()
                .trim()
                .required(
                  this.$t('auto-dialer.create.step1.form.destinations.required')
                ),
              callerNumber: string()
                .trim()
                .required(
                  this.$t('auto-dialer.create.step1.form.caller-ids.required')
                )
            })
          )
          .min(1, this.$t('auto-dialer.create.step1.form.callers.min')),
        agents: array()
          .of(
            string().required(
              this.$t('auto-dialer.create.step1.form.agent.required')
            )
          )
          .min(1, this.$t('auto-dialer.create.step1.form.agents.agent.min'))
      })
    },
    durationTypes() {
      return [
        {
          text: this.$t(
            'auto-dialer.create.step1.form.duration-type.options.time-limited'
          ),
          value: 'time-limited'
        },
        {
          text: this.$t(
            'auto-dialer.create.step1.form.duration-type.options.agent-availability'
          ),
          value: 'agent-availability'
        }
      ]
    },
    soundModes() {
      return [
        {
          text: this.$t(
            'auto-dialer.create.step1.form.sound-mode.options.no-sound'
          ),
          value: 'no-sound'
        },
        {
          text: this.$t(
            'auto-dialer.create.step1.form.sound-mode.options.play-sound'
          ),
          value: 'play-sound'
        }
      ]
    }
  },
  data() {
    return {
      loading: false,
      model: {
        name: '',
        durationType: 'time-limited',
        trialsCount: 1,
        wrapUpTime: 10,
        delayMinutesBetweenTrials: 5,
        hasAnnouncement: false,
        mainSoundFile: null,
        loopSoundFile: null,
        waitingCustomerCount: 0,
        fromTime: '',
        toTime: '',
        maxWaitTime: 50,
        timezone: '',
        callers: [{ destination: '', callerNumber: '' }],
        recording: {
          enabled: false,
          format: 'mp3',
          beep: false,
          maxDuration: 0
        },
        hideCallerInfo: false,
        agentCanLogoutAndRejoin: true,
        agents: []
      },
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      extensions: []
    }
  },
  methods: {
    // utils
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    // handlers
    handleAddCaller() {
      this.model.callers.push({ destination: '', callerNumber: '' })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async getExtensions() {
      try {
        const res = await ExtensionService.getAllExtensions()
        this.extensions = res.map(ext => ({
          ...ext,
          name: `${ext.name} (${ext.ext})`
        }))
      } catch (error) {
        this.toast(error.message, {
          type: 'error'
        })
      }
    },
    async submit() {
      try {
        this.loading = true
        this.errors = {}
        // get the data and remove all keys that's null
        const data = removeNullEntries({
          ...this.model,
          mainSoundFile:
            this.model.mainSoundFile && this.model.mainSoundFile[0],
          loopSoundFile:
            this.model.loopSoundFile && this.model.loopSoundFile[0],
          agents: this.model.agents.map(agent => agent.ext)
        })

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }

        const res = await AutoDialerService.createCampaign(data)
        const campaignId = res.data.campaign.id
        this.$router.push({
          name: 'auto-dialer-attach-customers',
          params: { id: campaignId }
        })
        this.toast(this.$t('auto-dialer.create.step1.toasts.campaign-created'))
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    let timezone = momentTimezone.tz.guess()
    const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
    if (!regex.test(timezone)) {
      timezone = 'Asia/Riyadh'
    }

    this.model.timezone = timezone

    this.getCountries()
    this.getCallerNumbers()
    this.getExtensions()
  }
}
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.callers-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
